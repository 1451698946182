import React from "react"
import PropTypes from "prop-types"

export default function Content(props) {
  return <div
    style={{
      background: "white",
      padding: "1.45rem 0 0 0"
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        minHeight: 500,
        padding: `0 1.0875rem 1.45rem`
      }}
    >
      <main>{props.children}</main>
    </div>
  </div>
}

Content.propTypes = { children: PropTypes.any }
