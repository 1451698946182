import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Button, Modal, Form, InputGroup } from "react-bootstrap"

export default function Subscribe() {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [show, setShow] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()
    const response = await addToMailchimp(email)
    setMessage(response.msg)
    setShow(true)
  }

  const handleClose = () => setShow(false)

  return (
    <>
      <hr />
      <h5>Feliratkozás a blogra</h5>
      <Form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="subscribe-form-label">email</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="email"
            aria-label="Email"
            aria-describedby="subscribe-form-label"
            placeholder="peti@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <InputGroup.Append>
            <Button variant="primary" type="submit">
              Feliratkozás
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
