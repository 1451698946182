import Subscribe from "./subscribe"
import React from "react"
import "./footer.css"
import logo from "./thin-01.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner">
        <img src={logo} aria-hidden="true" alt=""/>
        <div className="about">
          <h3>Az Útkeresés ima-blogja</h3>
          <p style={{ fontWeight: 100 }}>
            Ezzel a bloggal szeretnék segítségére lenni azoknak, akik a csendes
            ima útját keresik. Érdemes lehet itt körbenézned vagy feliratkoznod,
            ha még egyedül nehezen teszel lépéseket az imában. De akkor is, ha
            már gyakorlott imádkozó vagy, de szívesen imádkozol úgy, ha vezetve
            vagy, vagy ha új ötleteket keresel. Elsősorban a szentíráshoz
            kapcsolódód ritmikus és szentignáci szemlélődő imádságokat
            találhatsz itt leírva vagy hanganyagként.
            <br />
            <br />
            Várjuk és köszönjük visszajelzésedet!
            <br />
            <br />
            az Útkeresők
          </p>
        </div>

        <Subscribe />

        <hr/>

        <div className="kapcsolat"><h5>Elérhetőségek</h5>
          facebook: <a href="https://www.facebook.com/utkereseshaza/">fb.com/utkereseshaza</a> <br/>
          weboldal: <a href="https://utkereses.hu">utkereses.hu</a> <br/>
          email: <a href="mailto:kapcsolat@utkereses.hu">kapcsolat@utkereses.hu</a></div>
      </div>
    </div>
  )
}
