import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

export default function Header({ siteTitle }) {
  return (
    <header>
      <div className="header-inner">
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
